import { render, staticRenderFns } from "./Nalever.vue?vue&type=template&id=1ec19cb7&scoped=true&"
import script from "@/views/Editor/Nalever/Nalever.ts?vue&type=script&lang=ts&"
export * from "@/views/Editor/Nalever/Nalever.ts?vue&type=script&lang=ts&"
import style0 from "@/views/Editor/Nalever/nalever.scss?vue&type=style&index=0&id=1ec19cb7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec19cb7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ec19cb7')) {
      api.createRecord('1ec19cb7', component.options)
    } else {
      api.reload('1ec19cb7', component.options)
    }
    module.hot.accept("./Nalever.vue?vue&type=template&id=1ec19cb7&scoped=true&", function () {
      api.rerender('1ec19cb7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Editor/Nalever/Nalever.vue"
export default component.exports